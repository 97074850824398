import { lazy } from 'react';

// Enhanced retry logic for lazy loading
export function lazyWithRetry(factory: () => Promise<any>, retries = 3) {
  return lazy(() => {
    let attemptCount = 0;
    
    const attempt = (): Promise<any> => {
      return factory()
        .catch((error: Error) => {
          attemptCount += 1;
          if (attemptCount === retries) {
            // If all retries failed, throw the error
            throw error;
          }
          // Exponential backoff with max of 5 seconds
          const backoffTime = Math.min(1000 * Math.pow(2, attemptCount), 5000);
          return new Promise(resolve => {
            setTimeout(() => resolve(attempt()), backoffTime);
          });
        });
    };

    return attempt();
  });
}

// Preload function for critical components
export function preloadComponent(factory: () => Promise<any>) {
  const component = factory();
  component.catch(() => {}); // Silently handle preload errors
  return component;
}
