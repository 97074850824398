import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import type { User } from "db/schema";
import { LogLevel, logOperation } from "../lib/logging";

import type {
  User,
  LoginCredentials,
  RegisterCredentials,
  AuthResponse,
  UseAuth
} from '@/types/auth';

type RequestResult = {
  success: boolean;
  message: string;
};

async function handleRequest(
  url: string,
  method: string,
  body?: RegisterData | LoginData
): Promise<RequestResult> {
  try {
    const response = await fetch(url, {
      method,
      headers: body ? { "Content-Type": "application/json" } : undefined,
      body: body ? JSON.stringify(body) : undefined,
      credentials: "include",
    });

    const data = await response.json();
    // Only log errors, not successful operations
    if (!response.ok) {
      const maskedData = {
        ...data,
        email: data.email ? `${data.email[0]}***@${data.email.split('@')[1]}` : undefined,
        user_id: data.user_id ? `***${data.user_id.slice(-4)}` : undefined
      };
      logOperation('LoginAttempt', {
        status: 'failed',
        statusCode: response.status,
        error: maskedData.message,
        metadata: {
          timestamp: new Date().toISOString(),
          user_agent: window.navigator.userAgent
        }
      }, response.status === 401 ? LogLevel.ERROR : LogLevel.WARN);
    }

    if (!response.ok) {
      return { 
        success: false, 
        message: data.message || response.statusText 
      };
    }

    return { 
      success: true, 
      message: data.message || "Success" 
    };
  } catch (e: any) {
    logOperation('LoginAttempt', {
      status: 'error',
      error: e instanceof Error ? e.message : String(e),
      metadata: {
        timestamp: new Date().toISOString(),
        user_agent: window.navigator.userAgent
      }
    }, LogLevel.ERROR);
    return { 
      success: false, 
      message: e.toString() 
    };
  }
}

async function fetchUser(): Promise<User | null> {
  const response = await fetch('/api/user', {
    credentials: 'include'
  });
  
  // Remove debug logging for user data fetching
  
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  
  const data = await response.json();
  // Remove debug logging for successful user data retrieval
  return data;
}

export function useUser(): UseAuth {
  const queryClient = useQueryClient();
  const CACHE_KEY = 'cached_user';
  const CACHE_TIME = 5 * 60 * 1000; // 5 minutes
  
  // Initialize from cache if available
  useEffect(() => {
    const cachedData = sessionStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_TIME) {
        queryClient.setQueryData(['user'], data);
      } else {
        sessionStorage.removeItem(CACHE_KEY);
      }
    }
  }, [queryClient]);

  const { data: user, error, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: async ({ signal }) => {
      try {
        // Check cache first
        const cachedData = sessionStorage.getItem(CACHE_KEY);
        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          if (Date.now() - timestamp < CACHE_TIME) {
            // Return cached data immediately
            return data;
          }
        }

        // Fetch fresh data
        const freshData = await fetchUser();
        
        // Update cache with fresh data
        if (freshData) {
          sessionStorage.setItem(CACHE_KEY, JSON.stringify({
            data: freshData,
            timestamp: Date.now()
          }));
        } else {
          sessionStorage.removeItem(CACHE_KEY);
        }

        return freshData;
      } catch (error) {
        // Handle different types of auth errors
        if (error instanceof Error) {
          // Don't treat 401 as an error when not logged in
          if (error.message === '401' || error.message === 'Unauthorized') {
            sessionStorage.removeItem(CACHE_KEY);
            return null;
          }
          // Handle session expiration
          if (error.message === '403') {
            // Clear all caches
            queryClient.clear();
            localStorage.clear();
            sessionStorage.clear();
            return null;
          }
        }
        console.error('Auth error:', error);
        return null;
      }
    },
    retry: (failureCount, error) => {
      // Only retry on network errors or 500+ status codes
      if (error instanceof Error && error.message.includes('500')) {
        return failureCount < 3;
      }
      return false;
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    refetchOnWindowFocus: true,
    staleTime: CACHE_TIME,
    cacheTime: CACHE_TIME * 2, // Keep cache for twice as long as stale time
    refetchOnMount: false, // Don't refetch on mount if we have cached data
    refetchOnReconnect: 'always', // Always refetch on reconnect for security
  });

  const loginWithGitHub = () => {
    window.location.href = "/auth/github";
  };

  const register = useMutation({
    mutationFn: async (data: RegisterData) => {
      const result = await handleRequest('/api/register', 'POST', data);
      if (!result.success) {
        throw new Error(result.message);
      }
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const login = useMutation({
    mutationFn: async (data: LoginData) => {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        credentials: 'include'
      });
      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message);
      }
      return result;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ['user'] });
      window.location.href = '/dashboard'; // Always redirect to /dashboard
    },
  });

  const logout = useMutation({
    mutationFn: async () => {
      const result = await handleRequest("/api/logout", "POST");
      if (!result.success) {
        throw new Error(result.message);
      }
      return result;
    },
    onSuccess: () => {
      // Clear all client-side data
      queryClient.clear();
      localStorage.clear();
      sessionStorage.clear();
      // Remove any cookies
      document.cookie.split(";").forEach(function(c) { 
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      window.location.href = '/';
    },
  });

  return {
    user,
    isLoading,
    error,
    loginWithGitHub,
    register: register.mutateAsync,
    login: login.mutateAsync,
    logout: logout.mutateAsync,
  };
}