export enum LogLevel {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  FATAL = 'FATAL'
}

interface LogEntry {
  timestamp: string;
  level: LogLevel;
  operation: string;
  details: any;
  metadata?: {
    userAgent?: string;
    timestamp?: string;
    [key: string]: any;
  };
}

export function logOperation(operation: string, details: any, level: LogLevel = LogLevel.INFO) {
  const logEntry: LogEntry = {
    timestamp: new Date().toISOString(),
    level,
    operation,
    details,
    metadata: {
      userAgent: window.navigator.userAgent,
      timestamp: new Date().toISOString()
    }
  };

  // In development, log to console
  if (process.env.NODE_ENV === 'development') {
    console.log(`[${logEntry.level}] ${operation}:`, details);
  }

  // Send logs to server
  fetch('/api/logs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(logEntry),
    credentials: 'include'
  }).catch(() => {
    // Silently fail if logging endpoint is unavailable
    if (process.env.NODE_ENV === 'development') {
      console.warn('Failed to send log to server');
    }
  });
}
