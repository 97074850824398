import { toast } from "@/hooks/use-toast";

// Error types
export enum ErrorCode {
  NETWORK = 'NETWORK_ERROR',
  AUTH = 'AUTH_ERROR',
  VALIDATION = 'VALIDATION_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SERVER = 'SERVER_ERROR',
  RATE_LIMIT = 'RATE_LIMIT',
  UNKNOWN = 'UNKNOWN_ERROR'
}

export enum ErrorSeverity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical'
}

// Base error class
export class BaseError extends Error {
  constructor(
    message: string,
    public code: ErrorCode,
    public severity: ErrorSeverity = ErrorSeverity.MEDIUM,
    public timestamp: Date = new Date()
  ) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack;
    }
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      severity: this.severity,
      timestamp: this.timestamp.toISOString(),
      stack: this.stack
    };
  }
}

// API specific error
export class APIError extends BaseError {
  constructor(
    message: string,
    code: ErrorCode,
    public status: number,
    public details?: any,
    severity?: ErrorSeverity
  ) {
    super(message, code, severity);
    this.name = 'APIError';
  }
}

// Network error
export class NetworkError extends BaseError {
  constructor(message: string = 'Network connection error') {
    super(message, ErrorCode.NETWORK, ErrorSeverity.HIGH);
    this.name = 'NetworkError';
  }
}

// Authentication error
export class AuthError extends BaseError {
  constructor(message: string = 'Authentication failed') {
    super(message, ErrorCode.AUTH, ErrorSeverity.HIGH);
    this.name = 'AuthError';
  }
}

// Error logging utility
export const logError = (error: unknown) => {
  const errorObject = error instanceof BaseError ? error : new BaseError(
    error instanceof Error ? error.message : 'Unknown error',
    ErrorCode.UNKNOWN
  );

  console.error('Error logged:', {
    ...errorObject.toJSON(),
    timestamp: new Date().toISOString()
  });

  // Here you could add external error logging service integration
};

// Enhanced error handler
export const handleAPIError = (error: unknown) => {
  logError(error);

  if (error instanceof APIError) {
    const customMessage = (error.message?.includes('deployment could not be reached') || error.message?.includes('failed to fetch')) ?
      `Hey there! We're currently upgrading 1 Yr Net to bring you a better experience. The main site (1yr.net) will be down for just a few minutes while we switch things over.

In the meantime, feel free to visit our alternate site at beta.1yr.net to continue using the older version. We'll have everything back up and running on 1yr.net in no time.

Thank you for your patience and continued support!` : error.message;

    toast({
      title: error.name,
      description: customMessage,
      variant: "destructive",
    });

    switch (error.code) {
      case ErrorCode.AUTH:
        window.location.href = '/login';
        break;
      case ErrorCode.RATE_LIMIT:
        // Implement retry mechanism with exponential backoff
        break;
      case ErrorCode.NETWORK:
        // Attempt to reconnect
        break;
      default:
        if (error.severity === ErrorSeverity.CRITICAL) {
          // Handle critical errors (e.g., data corruption)
          window.location.reload();
        }
    }
    return;
  }

  if (error instanceof NetworkError) {
    toast({
      title: "Connection Error",
      description: "Please check your internet connection",
      variant: "destructive",
    });
    return;
  }

  // Default error handling
  toast({
    title: "Error",
    description: "An unexpected error occurred. Please try again later.",
    variant: "destructive",
  });
};

// Create standardized error response
export const createErrorResponse = (
  status: number,
  message: string,
  code: ErrorCode,
  details?: any,
  severity: ErrorSeverity = ErrorSeverity.MEDIUM
) => {
  return {
    success: false,
    error: message,
    code,
    severity,
    details,
    timestamp: new Date().toISOString()
  };
};

// Type guard
export const isAPIError = (error: any): error is APIError => {
  return error instanceof APIError || (
    error && 
    typeof error === 'object' && 
    'code' in error && 
    'status' in error &&
    'severity' in error
  );
};